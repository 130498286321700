import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  /**
   *
   */
  constructor(private router: Router) {
    // if (environment.moitruong && environment.moitruong.length > 0) {
    //   console.log(environment.moitruong)
    // }

  }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-HC7STT03M5', { 'page_path': event.urlAfterRedirects });
      }
    });
  }
}
