import { Component, EventEmitter, forwardRef, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { CauHinhCoreService } from 'src/app/services/core/cau-hinh.service';

@Component({
  selector: 'app-input-phone-number-regex',
  templateUrl: './input-phone-number-regex.component.html',
  styleUrls: ['./input-phone-number-regex.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneNumberRegexComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPhoneNumberRegexComponent),
      multi: true,
    }
  ]
})
export class InputPhoneNumberRegexComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() size: any = 'default';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() icon: string = '';
  @Input() idDonVi: any;
  @Input() uncheckValid: boolean = false;
  @Output() blur: EventEmitter<void> = new EventEmitter();
  regex: any = /^\+?\d{8,20}$/;
  phoneNumber: any = '';
  validPhoneNumber: boolean = false;
  errorMessage: any;

  constructor(
    private _cauHinhCore: CauHinhCoreService) {

  }

  ngOnInit() {
    this.getConfigRegex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uncheckValid && changes.uncheckValid.previousValue != this.uncheckValid) {
      this.onChange(this.phoneNumber);
    }
  }

  getConfigRegex() {
    this._cauHinhCore.GetCauHinhRegexPhone(this.idDonVi ?? '').then((res) => {
      if (res.success) {
        let _model = res.data;
        if (_model !== null) {
          let regexPhone = _model.regexPhone;
          if (regexPhone !== undefined && regexPhone !== null && regexPhone !== '') {
            this.regex = new RegExp(`^${regexPhone}$`);
          }
        }
      }
    });
  }

  onBlur(): void {
    this.blur.emit();
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.phoneNumber = value;
      this.validateAndFormatPhoneNumber();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if (this.uncheckValid) {
      return null;
    }
    if (this.validPhoneNumber) {
      return null;
    } else {
      return { invalidPhoneNumber: 'Số điện thoại không hợp lệ' };
    }
  }

  validateAndFormatPhoneNumber() {
    if (!this.required && (!this.phoneNumber || this.phoneNumber === '')) {
      this.validPhoneNumber = true;
      this.onChange(this.phoneNumber);
      return;
    }
    this.validPhoneNumber = this.regex.test(this.phoneNumber);
    this.onChange(this.phoneNumber);
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.blur.emit();
    }
  }
}
