import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import vi from '@angular/common/locales/vi';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NZ_I18N, vi_VN } from 'ng-zorro-antd/i18n';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './shared/template/template.module';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { GALLERY_CONFIG } from 'ng-gallery';
import { LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { CommonV2Interceptor } from './interceptor/commonv2.interceptor';
import { ColorPickerModule } from "ngx-color-picker";
import { CookieModule } from 'ngx-cookie';
import { ConfigCoreService } from './services/sanboxsarea/config.service';
import { ComponentsModule } from './components/components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonFacebookService } from './services/facebook/common.service';
import { ExtensionLayoutComponent } from './layout/extension-layout/extension-layout.component';
registerLocaleData(vi);


export function appInitializerFactory(_service: ConfigCoreService, _fbService: CommonFacebookService) {
  _service.loadModuleConfig();


  //load facebook sdk script
  // return () => new Promise(resolve => {
  //     // wait for facebook sdk to initialize before starting the angular app

  // });

  return () => _fbService.loadFacebookConfig();

}

const ngZorroConfig: NzConfig = {
  notification: { nzMaxStack: 1 },
  modal: { nzMaskClosable: true },
  table: { nzBordered: false },
  // pagination : {nzSize : 'small'},
  // button: {nzSize: 'small'}
};

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
    ExtensionLayoutComponent,
  ],
  imports: [
    ColorPickerModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    NzBreadCrumbModule,
    DragDropModule,
    TemplateModule,
    ComponentsModule,
    NgxSpinnerModule.forRoot({ type: 'spinnerRoot' }),
    provideFirebaseApp(() => initializeApp(environment.fireBaseConfig)),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.fireBaseConfig),
    CookieModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: CommonV2Interceptor,
    },
    {
      provide: NZ_I18N,
      useValue: vi_VN
    },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    ThemeConstantService,
    {
      provide: GALLERY_CONFIG,
      useValue: {
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        hasBackdrop: true
      }
    }, {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [ConfigCoreService, CommonFacebookService],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
