import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { AccountFbService } from './account-fb.service';

export class MyAppFBModule {
    fbAppVersion: string;
    fbLinkApi: string;
    fbTokenWebhook: string;
    fbAccessToken: string;
    fbAppSecret: string;
    fbAppId: string;
    fbMaxAutocreateSource: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonFacebookService extends BaseService {
  public static moduleConfig: MyAppFBModule;

  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.SERVICE_API_FACEBOOK}api/Common`);
  }

  FBGetConfig(ma) {
    const url = `${environment.SERVICE_API_FACEBOOK}api/Common/GetConfig?` + 'ma=' + ma;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  FBGetConfigFacebook() {
    const url = `${environment.SERVICE_API_FACEBOOK}api/Common/GetConfigFacebook`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  DropdownRabbitServerNumber() {
    const url = `${environment.SERVICE_API_FACEBOOK}api/Common/DropdownRabbitServerNumber`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  ///APP_INITIALIZER
  loadFacebookConfig() {
    if (!CommonFacebookService.moduleConfig) {

        CommonFacebookService.moduleConfig = new MyAppFBModule();
        CommonFacebookService.moduleConfig.fbAppId = environment.facebook.fbAppId;
        CommonFacebookService.moduleConfig.fbLinkApi = environment.facebook.fbLinkApi
        CommonFacebookService.moduleConfig.fbAppVersion = environment.facebook.fbAppVersion;
        CommonFacebookService.moduleConfig.fbTokenWebhook = environment.facebook.fbTokenWebhook;
        CommonFacebookService.moduleConfig.fbAccessToken = environment.facebook.fbAccessToken;
        CommonFacebookService.moduleConfig.fbAppSecret = environment.facebook.fbAppSecret;
        CommonFacebookService.moduleConfig.fbMaxAutocreateSource = environment.facebook.fbMaxAutocreateSource;

        const url = `${environment.SERVICE_API_FACEBOOK}api/Common/GetConfigFacebook`;
        this.get<ResponseData>(url).subscribe(rs => {
            if (rs.success) {
                CommonFacebookService.moduleConfig = rs.data;
            } else {
                //CommonFacebookService.moduleConfig = new MyAppFBModule();
            }
        })
    }

  }

  checkConfigAndLoadFacebookSDK() {
    if (!CommonFacebookService.moduleConfig) {

        CommonFacebookService.moduleConfig = new MyAppFBModule();
        CommonFacebookService.moduleConfig.fbAppId = environment.facebook.fbAppId;
        CommonFacebookService.moduleConfig.fbLinkApi = environment.facebook.fbLinkApi
        CommonFacebookService.moduleConfig.fbAppVersion = environment.facebook.fbAppVersion;
        CommonFacebookService.moduleConfig.fbTokenWebhook = environment.facebook.fbTokenWebhook;
        CommonFacebookService.moduleConfig.fbAccessToken = environment.facebook.fbAccessToken;
        CommonFacebookService.moduleConfig.fbAppSecret = environment.facebook.fbAppSecret;
        CommonFacebookService.moduleConfig.fbMaxAutocreateSource = environment.facebook.fbMaxAutocreateSource;

        //get config from server
        const url = `${environment.SERVICE_API_FACEBOOK}api/Common/GetConfigFacebook`;
        this.get<ResponseData>(url).subscribe(rs => {
            if (rs.success) {
                CommonFacebookService.moduleConfig = rs.data
            } else {
                //CommonFacebookService.moduleConfig = new MyAppFBModule();
            }

            console.log( CommonFacebookService.moduleConfig);

            this.loadFacebookSDK();
        })
    } else {
        this.loadFacebookSDK();
    }

  }

  loadFacebookSDK() {
        //load facebook sdk script by config
        // wait for facebook sdk to initialize before starting the angular app
        window['fbAsyncInit'] = function () {
            FB.init({
                appId: CommonFacebookService.moduleConfig.fbAppId,
                cookie: true,
                xfbml: true,
                version: CommonFacebookService.moduleConfig.fbAppVersion
            });

            FB.getLoginStatus(({authResponse}) => {
                if (authResponse) {
                    //console.log(authResponse.accessToken);
                    // _accountService.apiAuthenticate(authResponse.accessToken)
                    //     .subscribe(resolve);
                } else {
                    Promise.resolve();
                }
            });

            // //auto authenticate with the api if already logged in with facebook
            // FB.getLoginStatus(({authResponse}) => {
            //     if (authResponse) {
            //         console.log('vào1');
            //         _accountService.apiAuthenticate(authResponse.accessToken)
            //             .subscribe(resolve);
            //     } else {
            //         resolve(void 0);
            //     }
            // });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    

  }
}
