
import { ChinhanhService } from 'src/app/services/caidat-hethong/chinhanh.service';
import { MESSAGE } from 'src/app/constants/message';
import { ReadPropExpr } from '@angular/compiler';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserLogin } from 'src/app/models/userlogin';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/core/firebaseService';
import { NotificationService } from 'src/app/services/core/notification.service';
import { UserService } from 'src/app/services/core/user.service';
import { environment } from 'src/environments/environment';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { FormUserChangePassComponent } from '../form-user-changepass/form-user-changepass.component';
import { FormUserInfoComponent } from '../form-user-info/form-user-info.component';
import * as moment from 'moment';
import { calculateDiff } from '../../helper/ultility';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { NangCapGoiSuDungService } from 'src/app/services/caidat-hethong/nangcapgoisudung.service';
import { MenuConstantService } from '../../services/menu-constant.service';
import { TongDaiUserService } from 'src/app/services/swicthboard/tong-dai-user.service';
import { SwitchBoardCode } from '../../Enums/switchboard';
import { OmiCallService } from 'src/app/services/swicthboard/omicall.service';
import { TextToSpeechService } from 'src/app/services/TtsService';
import { Howl } from 'howler';
import { DonviService } from 'src/app/services/caidat-hethong/donvi.service';
import { PrintDonHangService } from 'src/app/services/print.donhang.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};

  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  userLogin: UserLogin;

  userAvatar: string = '';
  isNotification = false;
  notificationList: any[];
  soThongBao = 0;
  xemTatCa = true;
  listChiNhanh = [];
  isAdmin = false;
  isAdNhieuChiNhanh = false;
  idChiNhanh: any;

  isGiaHan = false;
  soNgayHetHan = 0;

  isCollapseExpried: boolean = false;
  isCollapseLink: boolean = false;

  linkDatLichDonVi = '';
  linkDatLichChiNhanh = '';
  linkDatLichNangCao = '';
  linkDatLichDonViText = 'Đặt lịch đơn vị';
  linkDatLichChiNhanhText = 'Đặt lịch chi nhánh';
  linkDatLichNangCaoText = 'Đặt lịch nâng cao';


  private messageQueue: any[] = [];
  private isProcessing = false;

  constructor(
    private themeService: ThemeConstantService,
    private authService: AuthService,
    private modal: NzModalService,
    private _userService: UserService,
    private _fireBaseService: FirebaseService,
    private cdRef: ChangeDetectorRef,
    private appRef: ApplicationRef,
    private notificationService: NotificationService,
    private notification: NzNotificationService,
    private chiNhanhService: ChinhanhService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _nangCapGoiSd: NangCapGoiSuDungService,
    private menuService: MenuConstantService,
    private _tongDaiUser: TongDaiUserService,
    private _omiCallService: OmiCallService,
    private textToSpeechService: TextToSpeechService,
    private donviService: DonviService,
    private _printDonHangService: PrintDonHangService
  ) {
    this._userService.reloadData$.subscribe(() => {
      this.loadData(); // Load dữ liệu lại khi có sự kiện reload
    });
  }

  ngOnInit(): void {
    this.loadData();

    this._fireBaseService.tokenChange().subscribe((b) => {
      localStorage.setItem('firebaseToken', b ?? '');

      localStorage.setItem('isDangKyNoti', '1');
      this.notificationService.registerToken(b ?? '').then((result) => { });
    });

    this._fireBaseService.receiveMessage().subscribe((value) => {
      if (value) {
        if (value.data && value.data["Action"] == "PRINT_DONHANG") {
          this._printDonHangService.SendNotiPrintDonHang(value.data);
        }
        //Kiểm tra nội dung là đọc thông báo hay không
        // if(value.data && value.data["Action"] == "DOCTEXT"){
        //   let textMessage = value.notification["body"];
        //   this.addToQueue(textMessage);
        //   return;
        // }

        this.notificationList = [...this.notificationList];
        const data = this.getNotificationData(value.data.notificationInfo);
        this.notificationList.unshift({
          title: value.notification.title,
          url: data.message['link'],
          image: data.message['image'] ?? '/assets/icons/icon.png',
          color: 'ant-avatar-' + 'blue',
          content: data.message['content'],
          time: this.getTime(data['ngayTao']),
          isView: false,
          id: data.Id,
        });
        this.soThongBao++;
        this.appRef.tick();
      }
    });
  }
  loadData() {
    // debugger
    // declare var window: any; // Needed on Angular 8+
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => {
        this.isFolded = isFolded;
      }
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this._userService.getCurrentUser().subscribe((res) => {
      if (res) {
        this.userLogin = res;
        this.idChiNhanh = this.userLogin.idChiNhanh;
        this.GetLinkDatLich();
        // this.linkDatLichDonVi = this.GetLinkDatLich();
        // this.linkDatLichChiNhanh =
        //   this.linkDatLichDonVi + `/${this.idChiNhanh}`;

        if (this.userLogin && this.userLogin.avatarUrl) {
          this.userAvatar = `${environment.SERVICE_API_FILE}Files/GetFile?id=${this.userLogin.avatarUrl}&size=m`;
        } else {
          this.userAvatar = `assets/images/avatar_default.png`;
        }
        if (
          this.userLogin.isAdmin ||
          this.userLogin.roleCodes.includes('HT_CSKH')
        ) {
          this.isAdmin = true;
          this.loadChiNhanh();
        }
        if (this.userLogin.roleCodes.includes('DV_AD_MCN')) {
          this.isAdNhieuChiNhanh = true;
          this.loadChiNhanhHasPermission();
        }

        this.dangkyfirebase();
      }
    });
    this.loadThoiHanGoi();

    var parentElement = document.getElementById('header-top');
    if (parentElement) {
      var parentWidth = parentElement.offsetWidth;
      if (parentWidth < 1200) {
        this.isCollapseExpried = true;
      } else {
        this.isCollapseExpried = false;
      }
      if (parentWidth < 700) {
        this.isCollapseLink = true;
      } else {
        this.isCollapseLink = false;
      }
    }

  }

  GetLinkDatLich() {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    if (this.linkDatLichDonVi === undefined || this.linkDatLichDonVi.trim() === '') {
      this.donviService.LayDatLichEndPointDonVi().subscribe((rs) => {
        let datLich = '/dat-lich';
        let datLichNangcao = '/dat-lich-nang-cao';
        // Fix cứng link đat
        // if (rs !== undefined && rs !== null && rs.data !== '') {
        //   endPoint = rs.data;
        // }

        this.linkDatLichDonVi = baseUrl + datLich;
        this.linkDatLichNangCao = baseUrl + datLichNangcao;
        this.linkDatLichChiNhanh =
          this.linkDatLichDonVi + `/${this.idChiNhanh}`;
      });
    }
  }

  dangkyfirebase() {
    this._fireBaseService.requestPermission().subscribe(
      (token) => {
        if (token === 'granted') {
          this._fireBaseService.getTokenApp().then((value) => {
          });
        }
      },
      (error) => {
        console.log('Unable to get perrmison to notify..', error);
      }
    );


  }

  loadChiNhanh() {
    this.chiNhanhService.LayListChiNhanh().then((rs) => {
      if (rs.success) {
        this.listChiNhanh = rs.data;
      }
    });
  }

  loadChiNhanhHasPermission() {
    this.chiNhanhService.LayListChiNhanhHasPermission().then((rs) => {
      if (rs.success) {
        this.listChiNhanh = rs.data;
      }
    });
  }

  ngAfterViewInit() {
    this.getNotification();
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.isExpand = !this.isExpand;
    this.themeService.toggleFold(this.isFolded);
    this.themeService.toggleExpand(this.isExpand);

    // this.menuService.changeMenuLoad([]);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  onCapNhatThongTin(event) {
    let _modal = this.modal.create({
      nzTitle: 'Cập nhật thông tin cá nhân',
      nzContent: FormUserInfoComponent,
      nzClosable: true,
      nzFooter: null,
      nzStyle: { top: '50px' },
      nzWidth: 1200,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var parentElement = document.getElementById('header-top');
    if (parentElement) {
      var parentWidth = parentElement.offsetWidth;
      if (parentWidth < 1200) {
        this.isCollapseExpried = true;
      } else {
        this.isCollapseExpried = false;
      }
      if (parentWidth < 700) {
        this.isCollapseLink = true;
      } else {
        this.isCollapseLink = false;
      }

    }

  }

  onChangePass(event) {
    let _modal = this.modal.create({
      nzTitle: 'Thay đổi thông tin mật khẩu',
      nzContent: FormUserChangePassComponent,
      nzClosable: true,
      nzFooter: null,
      nzStyle: { top: '50px' },
      nzWidth: 800,
    });
    _modal.afterClose.subscribe((res) => {
      if (res) {
        this.authService.logout();
      }
    });
  }

  logout() {
    this.modal.confirm({
      nzClosable: false,
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có chắc chắn muốn đăng xuất?',
      nzOkText: 'Đồng ý',
      nzOkDanger: true,
      nzWidth: '350px',
      nzOnOk: () => {
        this.authService.logout();
      },
    });
  }

  getNotification() {
    this.notificationService.getNotificationUser().subscribe((value) => {
      if (value.success) {
        const data = value.data?.map((e) => {
          return {
            title: e.message.title,
            url: e.message['link'],
            image: e.message['image'] ?? '/assets/icons/icon.png',
            color: 'ant-avatar-' + 'blue',
            content: e.message['content'],
            time: this.getTime(e['ngayTao']),
            isView:
              e.read?.findIndex((e) => {
                return e.userId == this.userLogin.id;
              }) > -1,
            id: e.Id,
            data: e?.data
          };
        });
        this.notificationList = [...data];
      } else {
        this.notificationList = [];
      }

      if (
        this.notificationList.findIndex((e) => {
          return e.isView == false;
        }) > -1
      ) {
        this.xemTatCa = true;
      } else {
        this.xemTatCa = false;
      }
      this.cdRef.markForCheck();
      this.appRef.tick();
    });

    this.notificationService.countUnReadNotification().subscribe((value) => {
      this.soThongBao = value.data;
    });
  }

  getNotificationData(notificationInfo) {
    return JSON.parse(notificationInfo);
  }

  getTime(date) {
    var duration = calculateDiff(date);
    if (duration.day > 0) {
      return duration.day + ' ngày trước';
    }
    if (duration.hour > 0) {
      return duration.hour + ' giờ trước';
    }
    if (duration.minute > 0) {
      return duration.minute + ' phút trước';
    }
    return 'vừa xong';
  }

  readNotification(item) {
    if (item?.data?.ma == 'MISS_CALL' && item?.data?.phone != null) {
      this._tongDaiUser.GetInfoUserSwitchboard().subscribe((rs) => {
        if (rs != null && rs?.IsHaveSwitchboard == true) {
          if (rs?.Data?.switchBoardCode == SwitchBoardCode.OmiCall) {
            this._omiCallService.setShowDialWithPhone(item?.data?.phone);
          }
        }
      });
    }
    if (!item.isView) {
      this.notificationService.readNotification(item.id).subscribe((data) => {
        if (data.success) {
          item.isView = true;
          this.soThongBao--;
          this.cdRef.markForCheck();
          this.appRef.tick();
          if (item.url) {
            window.open(item.url, '_blank');
          }
        }
      });
    } else {
      if (item.url) {
        window.open(item.url, '_blank');
      }
    }
  }

  readAllNotification(value) {
    // gọi APi để read tất cả các thông báo
    if (value == true) {
      this.notificationService.readAllNotification().subscribe((data) => {
        if (data.success) {
          this.soThongBao = 0;
          this.notificationList.forEach((e) => {
            e.isView = true;
          });
          this.notificationList = [...this.notificationList];
          this.cdRef.markForCheck();
          this.appRef.tick();
        }
      });
    }
  }

  changeViewNotification(event) {
    if (event == true) {
      this.getNotification();
    }
  }
  onChangeChiNhanh(item) {
    let model = {
      idChiNhanh: item.id,
    };
    this.spinner.show();
    this._userService.doiChiNhanh(model).then((rs) => {
      this.spinner.hide();
      if (rs.success) {
        this._userService.ClearCurrentUser();
        window.location.reload();
      } else {
        this.notification.warning(MESSAGE.WARNING, rs.message);
        this.loadChiNhanhHasPermission();
      }
    });
  }

  loadThoiHanGoi() {
    this._nangCapGoiSd.GetThoiHanGoi().then((rs) => {
      if (rs.success) {
        this.soNgayHetHan = rs.data;
        this.isGiaHan = this.soNgayHetHan < 30;
      }
    });
  }

  gotoGiaHan() {
    console.log(this.userLogin.roleCodes);
    if (
      this.userLogin.isAdmin ||
      this.userLogin.roleCodes.includes('DV_AD_CN') ||
      this.userLogin.roleCodes.includes('DV_AD_MCN') ||
      this.userLogin.roleCodes.includes('HT_CSKH')
    ) {
      this.router.navigate(['caidat/nang-cap-goi-su-dung']);
    }
  }

  onLinkDatLich(link: any) {
    window.open(link);
  }


  private addToQueue(message: any) {
    this.messageQueue.push(message);
    this.processQueue();
  }

  private async processQueue() {
    if (this.isProcessing || this.messageQueue.length === 0) {
      return;
    }

    this.isProcessing = true;

    const message = this.messageQueue.shift(); // Lấy thông báo đầu tiên ra khỏi hàng đợi
    await this.handleMessage(message); // Xử lý thông báo

    this.isProcessing = false;
    this.processQueue(); // Gọi lại hàm này để xử lý thông báo tiếp theo (nếu có)
  }

  private handleMessage(message: any): Promise<void> {
    return new Promise<void>((resolve) => {
      // Thực thi xử lý thông báo ở đây
      console.log('Handling message:', message);

      this.textToSpeechService.getSpeech(message).subscribe(
        (audioBlob) => {
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
          setTimeout(() => {
            resolve(); // Thông báo đã xử lý xong, tiếp tục xử lý thông báo tiếp theo
          }, 1000);
        },
        (error) => {
          console.error('Error playing speech:', error);
        }
      );

      // Giả sử quá trình xử lý mất 3 giây (bạn sẽ thay bằng logic thực tế của bạn)
      // setTimeout(() => {
      //   console.log('Message processed:', message);
      //   resolve(); // Thông báo đã xử lý xong, tiếp tục xử lý thông báo tiếp theo
      // }, 3000);
    });
  }

}
