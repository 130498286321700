import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { IBreadcrumb } from "../../shared/interfaces/breadcrumb.type";
import { ThemeConstantService } from '../../shared/services/theme-constant.service';

import { OmiCallService, OmicallData } from 'src/app/services/swicthboard/omicall.service';
import { OmiCallStatus, OmiCallViewType } from 'src/app/shared/Enums/switchboard';
import { MyHubService } from 'src/app/services/swicthboard/myhub.service';
import { TongDaiUserService } from 'src/app/services/swicthboard/tong-dai-user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-extension-layout',
  templateUrl: './extension-layout.component.html',
  styleUrls:['./extension-layout.component.scss']
})

export class ExtensionLayoutComponent implements OnInit {


  showLayout: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.firstChild?.data.subscribe((data) => {
      this.showLayout = !data['noLayout'];
    });
  }

}
