import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';

export interface ICauHinhCore {
  AllowMultiRole: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CauHinhCoreService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.CORE_API}api/CauHinh`);
  }

  GetCauHinhRegexPhone(idDonVi): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhRegexPhone/${idDonVi}`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetCauHinhVat(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhVat`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetCauHinhInit(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhInit`;
    return this.post<ResponseData>(url, null).toPromise();
  }

  GetCauHinhLumi(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhLumi`;
    return this.post<ResponseData>(url, null).toPromise();
  }

  GetCauHinhChung(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhChung`;
    return this.post<ResponseData>(url, null).toPromise();
  }

  SaveCauHinhInit(model): Promise<ResponseData> {
    const url = `${this.table}/SaveCauHinhInit`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  SaveCauHinhLumi(model): Promise<ResponseData> {
    const url = `${this.table}/SaveCauHinhLumi`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  SaveCauHinhChung(model): Promise<ResponseData> {
    const url = `${this.table}/SaveCauHinhChung`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  SaveLumiApiToken() {
    const url = `${this.table}/SaveLumiApiToken`;
    return this.post<ResponseData>(url, null).toPromise();
  }

  GetAllHome() {
    const url = `${this.table}/GetAllHome`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetLumiHomeDevice(lstIdHome) {
    const url = `${this.table}/GetLumiHomeDevice`;
    return this.post<ResponseData>(url, lstIdHome).toPromise();
  }

  GetDSChiNhanhConfigSmartDevice() {
    const url = `${this.table}/GetDSChiNhanhConfigSmartDevice`;
    return this.post<ResponseData>(url, null).toPromise();
  }

  SaveChiNhanhSmartDevice(payload): Promise<ResponseData> {
    const url = `${this.table}/SaveChiNhanhSmartDevice`;
    return this.post<ResponseData>(url, payload).toPromise();
  }

  // saveTokenExternal(apiUrl, payload): Promise<any> {
  //   const url = `${this.table}/Save`;
  //   return this.post<ResponseData>(url, payload).toPromise();
  // }

  GetStatusSmartDevice(): Promise<ResponseData> {
    const url = `${this.table}/GetStatusSmartDevice`;
    return this.post<ResponseData>(url, null).toPromise();
  }
  OnOffSmartDevice(body): Promise<ResponseData> {
    const url = `${this.table}/OnOffSmartDevice`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  CheckUserExportExcel() {
    const url = `${this.table}/CheckUserExportExcel`;
    return this.post<ResponseData>(url, null).toPromise();
  }
}
